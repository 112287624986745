<template>
  <el-dialog :title="title" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="80px" :disabled="isDisable">
      <el-form-item label="文章标题">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <!-- <el-form-item label="短介绍">
        <el-input v-model="form.shortDesc"></el-input>
      </el-form-item> -->
      <el-form-item label="文章图片">
        <el-upload
          v-if="dialogVisible"
          ref="upload"
          :class="{ hideUpload: hideUploadEdit }"
          action=""
          :file-list="fileList"
          :limit="1"
          list-type="picture-card"
          accept="image/jpg,image/jpeg,image/png"
          :auto-upload="false"
          :on-change="fileChange"
          :on-remove="fileChange"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="文章来源">
        <el-input v-model="form.source"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks"></el-input>
      </el-form-item>
      <el-form-item label="关联频道">
        <el-select v-model="channelId" multiple collapse-tags placeholder="请选择">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <quill-editor :disabled="isDisable" v-if="dialogVisible" class="editor" :options="editorOption" :content="content" @change="onEditorChange" />
      <div style="height: 100px"></div>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
import { createArticle, articleDetail, updateArticle, channelList } from '../api/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: '',
    isDisable: false
  },
  components: {
    quillEditor
  },
  computed: {
    title() {
      let state = '新建';
      if (this.isEdit) {
        state = '编辑';
      }
      return state + '文章';
    }
  },
  data() {
    return {
      // 富文本编辑器配置
      hideUploadEdit: false,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'] // 链接、图片、视频
          ]
        },
        placeholder: '请输入正文'
      },

      fileList: [],
      content: '',
      richText: '',
      form: {
        title: '',
        source: '',
        descriptions: '',
        shortDesc: ''
      },
      channelId: [],
      options: [],
      detailRes: {}
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.hideUploadEdit = false;
        this.fileList = [];
        this.channelId = [];
        this.content = '';
        this.richText = '';
        this.form = {
          title: '',
          source: '',
          descriptions: '',
          shortDesc: ''
        };

        this.getChennelList();
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    getChennelList() {
      channelList({ pageSize: 100 })
        .then((res) => {
          this.options = res.rows;
        })
        .catch((error) => {});
    },
    getDetail() {
      articleDetail({ id: this.currentId })
        .then((res) => {
          this.detailRes = res;

          (this.content = res.content), (this.richText = '');
          this.form = {
            title: res.title,
            source: res.source,
            descriptions: res.descriptions,
            shortDesc: res.shortDesc,
            remarks: res.remarks
          };
          if (res.channelIds && res.channelIds.length) {
            this.channelId = res.channelIds;
          }
          console.log(res.titleImgUrl);
          this.fileList = [{ name: '1', url: res.titleImgUrl }];
          if (this.fileList && this.fileList.length) {
            this.hideUploadEdit = true;
          }
        })
        .catch((error) => {});
    },

    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
      this.fileList = fileList;
    },
    closeMe(done) {
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      let toastList = [];
      if (!this.form.title) {
        toastList.push('请输入标题');
      }
      if (!this.richText.html) {
        toastList.push('请输入内容');
      }
      if (!this.fileList || this.fileList.length == 0) {
        toastList.push('请选择图片');
      }
      if (toastList.length) {
        this.$message({
          message: toastList.join(','),
          type: 'warning'
        });
        return;
      }

      let params = { ...this.form };
      params.content = this.richText.html;
      params.channelIds = this.channelId.join(',');

      let formData = new FormData();
      if (this.fileList && this.fileList.length && this.fileList[0].raw) {
        formData.append('file', this.fileList[0].raw);
      }
      formData.append('requestJson', JSON.stringify({ ...this.detailRes, ...params }));

      console.log(formData, '###');
      if (this.isEdit) {
        updateArticle(formData)
          .then((e) => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.closeMe();
            this.updateList();
          })
          .catch((error) => {});
      } else {
        createArticle(formData)
          .then((e) => {
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.closeMe();
            this.updateList();
          })
          .catch((error) => {});
      }
    },
    onEditorChange(e) {
      this.richText = e;
    },
    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 300px;
}
</style>
