<template>
  <el-dialog title="农事操作" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form ref="ruleForm" :model="form" label-width="120px" :rules="rules">
      <!-- <el-form-item label="名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="form.orderNum"></el-input>
      </el-form-item> -->
      <el-form-item label="农事类型" prop="farmingStage">
        <div class="flex-row-dia">
          <el-select v-model="form.farmingStage" placeholder="请选择" @change="option1Change">
            <el-option v-for="item in options1" :key="item.stageCode" :label="item.stageName" :value="item.stageCode"> </el-option>
          </el-select>
          <el-select v-model="form.farmingTypeId" placeholder="请选择">
            <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <!-- <el-input v-model="form.title"></el-input> -->
      </el-form-item>
      <el-form-item label="重要程度" prop="importanceLevel">
        <div class="flex-center">
          <el-rate v-model="form.importanceLevel" :max="10"></el-rate>
        </div>
      </el-form-item>
      <el-form-item label="日期" prop="startDate">
        <el-date-picker v-model="startDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="描述">
        <el-input type="textarea" autosize v-model="form.descriptions"></el-input>
      </el-form-item>
      <!-- <el-table :data="tableData" border>
        <el-table-column fixed prop="title" label="农资名称" width="200">
          <template slot-scope="scope">
            <el-select v-model="scope.row.type" placeholder="请选择">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="contractName" label="备注" width="250">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark"></el-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="addData(scope.row)">添加</el-button>
            <el-button type="text" size="small" @click="deleteData(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData" border>
        <el-table-column fixed prop="title" label="农具名称" width="200">
          <template slot-scope="scope">
            <el-select v-model="scope.row.type" placeholder="请选择">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="contractName" label="备注" width="250">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark"></el-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="addData2(scope.row)">添加</el-button>
            <el-button type="text" size="small" @click="deleteData2(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
                                                                                                                                                                                                                                                                                                                                                                  </el-table> -->
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="closeMe()">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { createPlanting, farmingList, updatePlanting } from '../api/api';
import dayjs from 'dayjs';
export default {
  props: {
    dialogVisible: false,
    isEdit: true,
    currentItem: null
  },
  components: {},
  computed: {},
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        farmingTypeId: [{ required: true, message: '请选择农事类型', trigger: 'blur' }],
        farmingStage: [{ required: true, message: '请选择农事类型', trigger: 'blur' }],
        importanceLevel: [{ required: true, validator: this.validateName1, trigger: 'blur' }],
        startDate: [{ required: true, validator: this.validateName, trigger: 'blur' }]
      },
      form: {
        descriptions: '',
        farmingTypeId: '',
        farmingStage: '',
        name: '',
        orderNum: '',
        importanceLevel: ''
      },
      option: {},
      startDate: [],
      options2: [],
      options1: [],
      tableData: [{ type: '', remark: '' }],
      tableData2: [{ type: '', remark: '' }]
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.form = {
          descriptions: '',
          farmingTypeId: '',
          farmingStage: '',
          name: '',
          orderNum: '',
          importanceLevel: ''
        };
        this.startDate = [];
        this.getFarming();
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    validateName(rule, value, callback) {
      this.startDate && this.startDate.length ? callback() : callback(new Error('请选择时间'));
    },
    validateName1(rule, value, callback) {
      this.form.importanceLevel ? callback() : callback(new Error('请选择重要程度'));
    },
    getDetail() {
      this.form = _.cloneDeep(this.currentItem);

      this.startDate = [this.currentItem.beginDate, this.currentItem.endDate];
    },
    option1Change(e) {
      this.options1.map((item) => {
        if (item.stageCode == e) {
          this.options2 = item.farmingTypes;
        }
      });
      this.form.farmingTypeId = '';
    },
    getFarming() {
      farmingList()
        .then((e) => {
          this.options1 = e;
          if (this.isEdit) {
            this.options1.map((item) => {
              if (item.stageCode == this.form.farmingStage) {
                this.options2 = item.farmingTypes;
              }
            });
          }
        })
        .catch((error) => {});
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let toastList = [];
          if (!this.form.farmingTypeId) {
            toastList.push('请选择类型');
          }
          if (!this.startDate.length) {
            toastList.push('请选择时间');
          }
          if (toastList.length) {
            this.$message({
              message: toastList.join(','),
              type: 'warning'
            });
            return;
          }
          let params = { ...this.form };
          if (this.startDate.length) {
            params.beginDate = this.startDate[0] ? dayjs(this.startDate[0]).format('YYYY-MM-DD HH:mm:ss') : '';
            params.endDate = this.startDate[1] ? dayjs(this.startDate[1]).format('YYYY-MM-DD HH:mm:ss') : '';
          }
          params.plantingSchemeId = this.$route.query.id;
          if (this.isEdit) {
            updatePlanting(params)
              .then((e) => {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
                this.closeMe();
                this.updateList();
              })
              .catch((error) => {});
          } else {
            createPlanting(params)
              .then((e) => {
                this.$message({
                  message: '创建成功',
                  type: 'success'
                });
                this.closeMe();
                this.updateList();
              })
              .catch((error) => {});
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closeMe() {
      this.$emit('update:dialogVisible', false);
    },
    updateList() {
      this.$emit('updateList');
    },
    addData() {
      this.tableData.push({ type: '', remark: '' });
    },
    deleteData(index) {
      this.tableData.splice(index, 1);
    },
    addData2() {
      this.tableData2.push({ type: '', remark: '' });
    },
    deleteData2(index) {
      this.tableData2.splice(index, 1);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row-dia {
  display: flex;
}

.flex-center {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}
</style>
